import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { SubTitle, BodyText, Title } from '@minerva-analytics/pantheon';
import SubscribeForm from '../../components/SubscribeForm';
import SubscribeJump from '../../components/SubscribeJump';
import PhoneMockup from '../../assets/phone_mockup.png';
import {
  HomeTheme,
  HeroContainer,
  HomeContainer,
  ImageContainer,
  Divider,
  MockupImage,
} from './Home.styles';

const Home = () => {
  const status = useSelector(
    (state) => state.subscription.createSubscriberStatus,
  );

  const success = status.loaded && !status.failed;

  const HeroSubscribe = () => (
    <React.Fragment>
      <SubTitle>History With a Twist</SubTitle>
      <Title>{`The Fascinating History \nof Everyday Objects`}</Title>
      <Divider />
      <BodyText>
        The lighter was invented before matches. We once used bread to erase
        pencil marks. Bubble wrap was originally created as a wallpaper.
      </BodyText>
      <BodyText>
        Back Then History brings you the fascinating history behind everyday
        objects. Enter your email below to start receiving your free history
        newsletter.
      </BodyText>
      <SubscribeForm />
    </React.Fragment>
  );

  return (
    <ThemeProvider theme={HomeTheme}>
      <HomeContainer>
        <HeroContainer>
          {success ? <SubscribeJump /> : HeroSubscribe()}
        </HeroContainer>
        <ImageContainer>
          <MockupImage src={PhoneMockup} />
        </ImageContainer>
      </HomeContainer>
    </ThemeProvider>
  );
};

export default Home;
